.home {
  width: 50%;
}

.financeModal {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 20px;
  height: 150px;
  width: 400px;
  background-color: white;
  border-radius: 4px;
  box-shadow: #690b93 0px 1px 9px;
}
.Fintitle {
  margin-top: 5px;
  text-align: center;
  color: #690b93;
  font-weight: bold;
  font-size: 16px;
  position: relative;
}
.CloseIcon {
  position: absolute;
  right: 2px;
  top: -5px;
  cursor: pointer;
}

.Finbody {
  margin-top: 5px;
  text-align: center;
  color: #690b93;
  font-size: 16px;
}
@media only screen and (max-width: 600px) {
  .financeModal {
    left: 10px;
    right: 0;
    bottom: 80px;
    width: 88%;
  }
}

.book-now-floating {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 20px;
  z-index: 9999999;
  animation: pulse 5s infinite;
}

@keyframes pulse {
  0% {
    background-color: #e4d6e4;
    color: #690b93;
  }
  100% {
    background-color: #690b93;
    color: #e4d6e4;
  }
}
.navbar {
  border: 0px !important;
  border-radius: 0px !important;
  padding: 0.625rem !important;
  margin-bottom: 0px !important;
  height: auto !important;
  color: #555;
  background-color: #fff !important;
  box-shadow: 0 4px 18px 0px rgba(0, 0, 0, 0.12),
    0 7px 10px -5px rgba(0, 0, 0, 0.15);
}
/* Style the button and place it in the middle of the container/image */
.home .btn {
  position: absolute;
  top: 47%;
  left: 43%;
  background-color: #669999;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.home .btn:hover {
  background-color: black;
}

.home2 {
  width: 50%;
}

/* Style the button and place it in the middle of the container/image */
.home2 .btn {
  position: absolute;
  top: 47%;
  left: 43%;
  background-color: #669999;
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.home2 .btn:hover {
  background-color: black;
}

.text {
  text-align: center;
}

.under-and-over {
  text-decoration-line: underline overline;
  text-decoration-style: wavy;
  text-decoration-color: #2bbbad;
}

.homebtn {
  background-color: #e4d6e4;
  border-color: #690b93;
  border-radius: 30px;
  font-weight: bolder;
  color: #690b93;
  height: 60px;
}
.homebtn:hover {
  background-color: #690b93;
  border-width: medium;
  font-size: x-large;
  color: #e4d6e4;
}

.navbtn {
  background-color: #690b93;
  border-color: #690b93;
  border-radius: 5px;
  font-weight: bolder;
  font-size: large;
  color: #e4d6e4;
}
.navbtn:hover {
  background-color: #e4d6e4;
  border-color: #e4d6e4;
  border-width: medium;
  font-size: large;
  color: #690b93;
}

.page-section {
  padding: 6rem 0;
  padding-bottom: 0px;
}
.homeicon {
  color: #669999;
}
hr.divider {
  max-width: 3.25rem;
  border-width: 0.2rem;
  border-color: #690b93;
}

header.masthead {
  padding-top: 1rem;
  padding-bottom: calc(10rem - 6.5rem);
  background: white;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}
header.masthead h1 {
  font-size: 2.25rem;
}
@media (min-width: 992px) {
  header.masthead {
    height: 100vh;
    min-height: 20rem;
    padding-top: 0rem;
    padding-bottom: 0;
  }
  header.masthead p {
    font-size: 1.15rem;
  }
  header.masthead h1 {
    font-size: 3rem;
  }
}
@media (min-width: 1200px) {
  header.masthead h1 {
    font-size: 3.5rem;
  }
}

header.masthead1 {
  padding-top: 5rem;
  padding-bottom: calc(10rem - 4.5rem);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

body {
  background: aliceblue;
}

.gtco-testimonials {
  position: relative;
  margin-top: 30px;
}
.gtco-testimonials h2 {
  font-size: 30px;
  text-align: center;
  color: #333333;
  margin-bottom: 50px;
}
.gtco-testimonials .owl-stage-outer {
  padding: 30px 0;
}
.gtco-testimonials .owl-nav {
  display: none;
}
.gtco-testimonials .owl-dots {
  text-align: center;
}
.gtco-testimonials .owl-dots span {
  position: relative;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: block;
  background: #fff;
  border: 2px solid #01b0f8;
  margin: 0 5px;
}
.gtco-testimonials .owl-dots .active {
  box-shadow: none;
}
.gtco-testimonials .owl-dots .active span {
  background: #01b0f8;
  box-shadow: none;
  height: 12px;
  width: 12px;
  margin-bottom: -1px;
}
.gtco-testimonials .card {
  background: #fff;
  padding: 0 10px;
  border-radius: 20px;
  border: 0;
}
.gtco-testimonials .card .card-img-top {
  max-width: 100px;
  border-radius: 50%;
  margin: 15px auto 0;
  box-shadow: 0 8px 20px -8px #690b93;
  width: 100px;
  height: 100px;
}
.gtco-testimonials .card h5 {
  color: #690b93;
  font-size: 21px;
  line-height: 1.3;
}
.gtco-testimonials .card h5 span {
  font-size: 18px;
  color: #666666;
}
.gtco-testimonials .card p {
  font-size: 18px;
  color: #555;
  padding-bottom: 15px;
}
.gtco-testimonials .active {
  opacity: 0.5;
  transition: all 0.3s;
}
.gtco-testimonials .center {
  opacity: 1;
}
.gtco-testimonials .center h5 {
  font-size: 24px;
}
.gtco-testimonials .center h5 span {
  font-size: 20px;
}
.gtco-testimonials .center .card-img-top {
  max-width: 100%;
  height: 120px;
  width: 120px;
}

@media (max-width: 767px) {
  .gtco-testimonials {
    margin-top: 20px;
  }
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  outline: 0;
}
.owl-carousel button.owl-dot {
  outline: 0;
}

.btn-shine {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 48px;
  color: #fff;
  background: linear-gradient(to right, #e4d6e4 10%, #690b93 20%, #e4d6e4 30%);
  background-position: 0;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 2s infinite 1s;
  animation-fill-mode: both;
  -webkit-text-size-adjust: none;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
@-moz-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-webkit-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@-o-keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}
@keyframes shine {
  0% {
    background-position: 0;
  }
  60% {
    background-position: 180px;
  }
  100% {
    background-position: 180px;
  }
}

.table {
  border-radius: 25px;
}

.table-dark {
  color: #e9dde9 !important;
  background-color: #212529c7 !important;
}
